<template>
  <dashboard-layout>
    <div class="w-full p-5">
      <div class="flex flex-col">
        <div class="w-11/12 mx-auto">
          <div
            class="flex border-2 border-green-1002 flex px-3 py-1 rounded-lg w-min"
          >
            <router-link :to="{ name: 'my-curriculum' }">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="32"
                height="32"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"
                  fill="rgba(0,122,111,1)"
                />
              </svg>
            </router-link>
          </div>
          <form class="mt-4" @submit.prevent="submit">
            <h2 class="text-2xl mb-4 font-bold">Editar Formação Acadêmica</h2>

            <div class="flex flex-col mb-3">
              <label class="flex items-center mb-2" for="education-level">
                *Escolaridade:
                <span v-if="$v.educationLevels.selected.$error">
                  <span
                    class="error"
                    v-if="!$v.educationLevels.selected.required"
                  >
                    Campo obrigatório.
                  </span>
                </span>
              </label>
              <multiselect
                id="education-level"
                v-model="$v.educationLevels.selected.$model"
                deselect-label="Remover"
                track-by="name"
                label="name"
                select-label="Selecionar"
                selected-label="Selecionado"
                placeholder="Selecione uma escolaridade"
                :options="educationLevels.options"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  {{ option.name }}
                </template>
                <span slot="noOptions">Nenhuma escolaridade encontrada.</span>
              </multiselect>
            </div>
            <div class="flex flex-col">
              <label class="mb-2" for="institution">
                *Instituição:
                <span v-if="$v.institution.$error">
                  <span class="error" v-if="!$v.institution.required">
                    Campo obrigatório.
                  </span>
                  <span class="error" v-else-if="!$v.institution.maxLength">
                    A instituição deve ter no máximo
                    {{ $v.institution.$params.maxLength.max }} caracteres.
                  </span>
                </span>
              </label>
              <input
                v-model="$v.institution.$model"
                class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="institution"
                type="text"
                placeholder="Instituição"
                autocomplete="off"
              />
            </div>

            <div class="flex flex-col">
              <label class="mb-2" for="formation">
                *Formação:
                <span v-if="$v.formation.$error">
                  <span class="error" v-if="!$v.formation.required">
                    Campo obrigatório.
                  </span>
                  <span class="error" v-else-if="!$v.formation.maxLength">
                    A formação deve ter no máximo
                    {{ $v.formation.$params.maxLength.max }} caracteres.
                  </span>
                </span>
              </label>
              <input
                v-model="$v.formation.$model"
                class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="formation"
                type="text"
                placeholder="Formação"
                autocomplete="off"
              />
            </div>

            <div class="flex-col">
              <label class="mb-2" for="started-at">
                *Data de Início:
                <span v-if="$v.startedAt.$error">
                  <span class="error" v-if="!$v.startedAt.required">
                    Campo obrigatório.
                  </span>
                </span>
              </label>
              <input
                v-model="$v.startedAt.$model"
                class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="started-at"
                type="text"
                placeholder="Data de Início"
                v-mask="'##/##/####'"
                autocomplete="off"
              />
            </div>

            <div class="flex-col">
              <label class="mb-2" for="ended-at">Data de Fim</label>
              <input
                v-model="endedAt"
                class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="ended-at"
                type="text"
                placeholder="Data de Fim"
                v-mask="'##/##/####'"
                autocomplete="off"
              />
            </div>

            <div class="flex justify-between">
              <button
                type="submit"
                class="bg-green-1000 text-white px-4 py-2 rounded rounded-md border border-gray-400 focus:outline-none mt-5"
              >
                Salvar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </dashboard-layout>
</template>

<script>
import { maxLength, required } from 'vuelidate/lib/validators';
import { mask } from 'vue-the-mask';
import { mapGetters } from 'vuex';
import axios from '@/utils/axios';
import DashboardLayout from '../../../../layouts/DashboardLayout';

export default {
  name: 'AcademicBackgroundEdit',

  title() {
    return `${process.env.VUE_APP_NAME} | Editar Formação Acadêmica`;
  },

  props: {
    id: {
      required: true,
      type: Number
    }
  },

  components: {
    DashboardLayout
  },

  directives: {
    mask
  },

  data() {
    return {
      educationLevels: {
        options: [],
        selected: null
      },
      institution: '',
      formation: '',
      startedAt: '',
      endedAt: ''
    };
  },

  validations: {
    educationLevels: {
      selected: {
        required
      }
    },
    institution: {
      required,
      maxLength: maxLength(255)
    },
    formation: {
      required,
      maxLength: maxLength(255)
    },
    startedAt: {
      required
    }
  },

  computed: {
    ...mapGetters('auth', {
      user: 'user'
    })
  },

  methods: {
    fetchAcademicBackground() {
      axios
        .get(`/api/formacoes-academicas/${this.id}/editar`)
        .then(({ data }) => {
          this.educationLevels.selected = data.data.education_level;
          this.institution = data.data.institution;
          this.formation = data.data.formation;
          this.startedAt = data.data.complete_started_at;
          this.endedAt = data.data.complete_ended_at;
        });
    },

    submit() {
      if (this.$v.$invalid) {
        this.$v.educationLevels.selected.$touch();
        this.$v.institution.$touch();
        this.$v.formation.$touch();
        this.$v.startedAt.$touch();
      } else {
        let attributes = {
          education_level_id: this.educationLevels.selected.id,
          institution: this.institution,
          formation: this.formation,
          started_at: this.startedAt,
          ended_at: this.endedAt
        };

        axios
          .patch(`/api/formacoes-academicas/${this.id}`, attributes)
          .then(({ data }) => {
            this.$toast.success(data.data.message);

            this.$router.push({
              name: 'my-curriculum'
            });
          })
          .catch(({ response }) => {
            Object.values(response.data.errors).forEach(error => {
              this.$toast.error(...error);
            });
          });
      }
    },

    async fetchEducationLevels() {
      const response = await axios.get('api/escolaridades');

      response.data.data.forEach(educationLevel => {
        this.educationLevels.options.push({
          name: educationLevel.name,
          id: educationLevel.id
        });
      });
    }
  },

  created() {
    this.fetchEducationLevels();
    this.fetchAcademicBackground();
  }
};
</script>

<style scoped></style>
